import React from 'react';
import GatsbyLink from 'gatsby-link';
import Img from "gatsby-image"
export default function CollectionLg({
  heading,
  href,
  imageSource
 }){

const imageStyle = {
  position: `unset`
}

  return (
    <div class="col-lg-6 col-md-6 col-sm-6">
        <div class="single-banner banner-style-two mb-30 ccnCollection ccnCollectionLg">
            <div class="banner-img">
                <GatsbyLink to={href}>
                  {typeof imageSource === 'object' && imageSource !== null && <Img fluid={imageSource} alt={heading} objectFit="cover" style={imageStyle} />}
                  {typeof imageSource !== 'object' && imageSource !== null && <img src={imageSource} alt={heading} style={imageStyle} />}
                  <span class="title left">{heading}</span>
                </GatsbyLink>
            </div>
        </div>
    </div>
  );
}
