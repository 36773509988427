import React from 'react';
import { useStaticQuery, graphql } from 'gatsby'
import CollectionXl from '../../components/Commons/CollectionXl'
import CollectionLg from '../../components/Commons/CollectionLg'
import CollectionMdTall from '../../components/Commons/CollectionMdTall'
import CollectionMdLong from '../../components/Commons/CollectionMdLong'
import CollectionSm from '../../components/Commons/CollectionSm'
// import { StateConsumer } from '../../../../context/StateContext'
import Layout from '../../components/Layout'
// import NewsletterSubscribe from '../blocks/NewsletterSubscribe'

const CatalogItem = ({ data, size }) => {

  const {
    nodeName,
    nodeImage,
    pageUrl,
    fileNodeImage
  } = data

  let imageSrc = nodeImage

  if(fileNodeImage !== null) imageSrc = fileNodeImage.childImageSharp.fluid

  if(size === 'xl') {
    return (
      <CollectionXl heading={nodeName} imageSource={imageSrc} href={pageUrl} />
    )
  }
  if(size === 'lg') {
    return (
      <CollectionLg heading={nodeName} imageSource={imageSrc} href={pageUrl} />
    )
  }
  if(size === 'md-long') {
    return (
      <CollectionMdLong heading={nodeName} imageSource={imageSrc} href={pageUrl} />
    )
  }
  if(size === 'md-tall') {
    return (
      <CollectionMdTall heading={nodeName} imageSource={imageSrc} href={pageUrl} />
    )
  }
  if(size === 'sm') {
    return (
      <CollectionSm heading={nodeName} imageSource={imageSrc} href={pageUrl} />
    )
  }
  return null
}

function Catalog({ data }) {

  const {
    allFormlightCatalog,
    formlightCommon
  } = data

  const { nodes } = allFormlightCatalog

  const {
    catalog_title,
    catalog_desc
  } = formlightCommon

  let nodesSmall = nodes.slice(6)

  return (
    <Layout>
      <div class="shop-collections-area section pt-75 pt-lg-55 pt-md-55 pt-sm-50 pt-xs-40  pb-100 pb-lg-80 pb-md-70 pb-sm-60 pb-xs-50">
        <div class="container-fluid container-fluid-minimus pl-lg-15 pl-md-15 pl-sm-15 pl-xs-15 pr-lg-15 pr-md-15 pr-sm-15 pr-xs-15">
            <div class="row">
                <div class="col-12">
                    <div class="shop-collection-content">
                        <h2 class="title">{ catalog_title }</h2>
                        <p>{catalog_desc}</p>
                    </div>
                </div>
            </div>
            <div class="row">
              {nodes[0] && <CatalogItem data={nodes[0]} size="xl" />}
              <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="row">
                  {nodes[1] && <CatalogItem data={nodes[1]} size="md-long" />}
                  {nodes[2] &&<CatalogItem data={nodes[2]} size="md-long" />}
                </div>
              </div>
              {nodes[3] &&<CatalogItem data={nodes[3]} size="md-tall" />}
              {nodes[4] &&<CatalogItem data={nodes[4]} size="md-tall" />}
              {nodes[5] &&<CatalogItem data={nodes[5]} size="lg" />}
              {nodesSmall.map((item) => {
                return (
                  <CatalogItem data={item} size="sm" />
                )
              })}
          </div>
        </div>
      </div>
    </Layout>
  )

}

export const query = graphql`
query allFormlightCatalog {
  allFormlightCatalog(filter: {nodeDepth: {eq: 0}}) {
    nodes {
      fileNodeImage {
        publicURL
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      nodeImage
      nodeDesc
      nodeName
      pageUrl
    }
  }
  formlightCommon {
    catalog_title
    catalog_desc
  }
}`

export default Catalog;
