import React from 'react';
import GatsbyLink from 'gatsby-link';
import Img from "gatsby-image"
export default function CollectionMdLong({
  heading,
  href,
  imageSource
 }){

   const imageStyle = {
     position: `unset`
   }

   return (
     <div class="col-12">
        <div class="single-banner ccnCollectionMdLong ccnCollection banner-style-two mb-30">
            <div class="banner-img">
                <GatsbyLink to={href}>
                  {typeof imageSource === 'object' && imageSource !== null && <Img fluid={imageSource} alt={heading} objectFit="cover" style={imageStyle} />}
                  {typeof imageSource !== 'object' && imageSource !== null && <img src={imageSource} alt={heading} style={imageStyle} />}
                  <span class="title left">{heading}</span>
                </GatsbyLink>
            </div></div></div>
  );
}
